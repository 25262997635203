import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import CryptoJS from 'crypto-js'; // Use crypto-js instead of crypto
// material
import { Link, Stack, Alert, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// routes
import { PATH_AUTH, PATH_CHAT } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MHidden, MIconButton } from '../../@material-extend';
import axiosInstance from '../../../utils/axios';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const sso = new URLSearchParams(location.search).get('sso');
  const sig = new URLSearchParams(location.search).get('sig');

  useEffect(() => {
    handleSSO();
  }, [sso, sig]);

  const handleSSO = async () => {
    if (sso && sig) {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          const response = await axiosInstance.get('/account/info', {
            headers: {
              'auth_token': accessToken
            }
          });
          console.log('login response:', response);
          const userData = response.data;

          const discourseSecret = 'FMConnect123!';
          const payload = atob(sso);
          const params = new URLSearchParams(payload);
          const nonce = params.get('nonce');

          const userPayload = {
            nonce: nonce,
            email: userData.email,
            external_id: userData.user_id,
            username: `${userData.firstname}-${userData.surname}`,
            name: `${userData.firstname} ${userData.surname}`
          };

          console.log('userPayload:', userPayload);

          const base64Payload = btoa(new URLSearchParams(userPayload).toString());
          const newSig = CryptoJS.HmacSHA256(base64Payload, discourseSecret).toString(CryptoJS.enc.Hex);

          const redirectUrl = `${params.get('return_sso_url')}?sso=${encodeURIComponent(base64Payload)}&sig=${newSig}`;
          window.location.href = redirectUrl;
        }
      } catch (error) {
        console.error("SSO Error:", error);
      }
    }
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const { message, error } = await login(values.email, values.password);
  
        enqueueSnackbar(message || error || 'Login successful.', {
          variant: message || error ? 'error' : 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (!message && !error) {
          if (sso && sig) {
            await handleSSO();
          } else {
            navigate(PATH_CHAT.root);
          }
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Username or email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <LoadingButton
            disableRipple
            disableElevation
            fullWidth
            sx={{
              background: 'linear-gradient(332.37deg, #47CCBB -12.09%, #194D92 81.83%)'
            }}
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot your password?
          </Link>
        </Stack>
        <MHidden width="smDown">
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Typography variant="body2" align="center">
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Register
              </Link>
            </Typography>
          </Stack>
        </MHidden>
      </Form>
    </FormikProvider>
  );
}