import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
policy_options: {},
toc_data: {},
policyData:[],
loadingPolicyData:[]
};

const slice = createSlice({
  name: 'wiki',
  initialState,
  reducers: {
    // LOADING
    updateLoadingState(state,payload) {
      state.isLoading = payload;
    },

   
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPolicyOptions(state,{payload}) {
        state.policy_options = payload;
      },

    getTOC(state,{payload}) {
        state.toc_data = payload;
      },

    setLoadingPolicyData(state,{payload}) {
      state.loadingPolicyData = [...state.loadingPolicyData, ...payload];
      },

      setPolicyData(state, { payload }) {
        state.policyData = [...state.policyData, ...payload];
      },
  
      clearLoadingPolicyData(state) {
        state.loadingPolicyData = [];
      },

      

  }
});

// Reducer
export default slice.reducer;

// Actions
export const { handleClearChat,updateLoadingState,getPolicyOptions ,getTOC,setPolicyData,setLoadingPolicyData,clearLoadingPolicyData} =
  slice.actions;



// ----------------------------------------------------------------------


export function fetchPolicyOptions() {
  return async (dispatch) => {
      try {
          const response = await axiosInstance.get('/writing/policy_options');
          dispatch(slice.actions.getPolicyOptions(response.data));
      } catch (error) {
          console.error(error);
          dispatch(slice.actions.hasError(error));
      }
  };
}

export function fetchTOC(policy_type,policy_context) {
  return async (dispatch) => {
      try {
          const response = await axiosInstance.post('/writing/table_of_contents',{
            policy_type,
            policy_context
          });
          dispatch(slice.actions.getTOC(response.data));
      } catch (error) {
          console.error(error);
          dispatch(slice.actions.hasError(error));
      }
  };
}