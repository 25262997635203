import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // const isDashboard = pathname.includes('/dashboard');

  return (
    <>
      <Suspense
        fallback={
          <>loading...</>
          // <LoadingScreen
          //   sx={{
          //     ...(!isDashboard && {
          //       top: 0,
          //       left: 0,
          //       width: 1,
          //       zIndex: 9999,
          //       position: 'fixed'
          //     })
          //   }}
          // />
        }
      >
        <Component {...props} />
      </Suspense>
    </>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { path: '', element: <Navigate to="/auth/login" replace /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> }
      ]
    },

    // chat Routes
    {
      path: 'chat',
      element: (
        <AuthGuard>
          <ChatLayout />
        </AuthGuard>
      )
    },
    {
      path: 'wiki',
      element: (
        <AuthGuard>
          <WikiLayout />
        </AuthGuard>
      )
    },

    // profile Routes
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <UserProfile />
        </AuthGuard>
      )
    },
    {
      path: 'writing_assistant',
      element: (
        <AuthGuard>
          <WritingAssistantLayout />
        </AuthGuard>
      )
    },

    // Main Routes
    { path: '*', element: <Navigate to="/auth/login" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ChatLayout = Loadable(lazy(() => import('../layouts/chat')));
const WikiLayout = Loadable(lazy(() => import('../layouts/wiki')));
const WritingAssistantLayout = Loadable(lazy(() => import('../layouts/writing_assistant')));
const UserProfile = Loadable(lazy(() => import('../pages/profile/UserProfile')));
// Dashboard
